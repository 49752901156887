$(function() {
    const $input = $('#location-input');

    if ($input.length) {
        const $form = $input.parents('form');
        const $inputLocationName = $('input[name="location_name"]');
        const $inputLat = $('input[name="lat"]');
        const $inputLng = $('input[name="lng"]');

        let latLngPopulated = false;
        let geocoder = new google.maps.Geocoder();
        let autocomplete = new google.maps.places.Autocomplete($input.get(0), {
            componentRestrictions: { country: 'uk' }
        });

        autocomplete.addListener('place_changed', () => {
            let location = autocomplete.getPlace();

            if (location.hasOwnProperty('geometry')) {
                latLngPopulated = true;
                $inputLocationName.val(location.address_components[0].long_name);
                $inputLat.val(location.geometry.location.lat());
                $inputLng.val(location.geometry.location.lng());
            }
        });

        $input.on('keyup', () => {
            if (latLngPopulated) {
                latLngPopulated = false;
                $inputLat.val('');
                $inputLng.val('');
            }
        });

        $form.on('submit', () => {
            if ($form.find('.callout').length) {
                $form.find('.callout').remove();
            }

            $form.addClass('loading');

            // validate that there's actually a location set
            if (!$input.val()) {
                $form.removeClass('loading');
                createFormCallout($form, 'alert', 'Please enter a location');
                return false;
            }

            // check if we have a correct latitude and longitude to work with
            if (!$inputLat.val() || !$inputLng.val()) {
                geocoder.geocode({
                    address: $input.val(),
                    componentRestrictions: { country: 'uk' }
                }, (results, status) => {
                    if (status === 'OK') {
                        let result = results[0];
                        let location = result.geometry.location;

                        $inputLocationName.val(result.address_components[0].long_name);
                        $inputLat.val(location.lat());
                        $inputLng.val(location.lng());

                        // re-submit the form so the updated details are sent correctly
                        $form.submit();
                    } else {
                        createFormCallout($form, 'alert',
                            'We were unable to geocode your location. Please select an option from the autocomplete list.'
                        );

                        $form.removeClass('loading');
                    }
                });

                return false;
            }
        });
    }

    const createFormCallout = ($form, type, message) => {
        let $el = $('<div />', {
            class: 'form-notification callout small ' + type,
            text:  message
        });

        if ($form.find('.callout').length) {
            $form.find('.callout').remove();
        }

        $el.prependTo($form);
    };
});