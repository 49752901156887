$(function() {
    const $filterSkip = $('#filter-skip');
    const $filterSort = $('#filter-sort');
    const $scrollToTop = $('.scroll-to-top');

    if ($filterSkip.length) {
        const $results = $('.agent-listing');

        $filterSkip.on('change', () => {
            let index = ($filterSkip.val() - 1);

            if ($results[index]) {
                $('html, body').animate({ 'scrollTop' : $($results[index]).offset().top - 35 });
            }
        });
    }

    if ($filterSort.length) {
        const url = window.site.pageUri;

        $filterSort.on('change', () => {
            let sortOptions = $filterSort.val().split(':');
            window.location = url + '?sort=' + sortOptions[0] + '&order=' + sortOptions[1];
        });
    }

    if ($scrollToTop.length) {
        $scrollToTop.on('click', (event) => {
            event.preventDefault();
            $('html, body').animate({ 'scrollTop' : 0 });
        });

        $(window).on('scroll', () => {
            if ($(window).scrollTop() > 4800) {
                $scrollToTop.addClass('show');
            } else {
                $scrollToTop.removeClass('show');
            }
        });
    }
});