$(function() {
    const $trigger = $('#open-offscreen-menu');
    const $menu = $('#guide-menu-about');

    if ($trigger.length && $menu.length) {
        const $body = $('body');
        const $closeTrigger = $menu.find('.menu-offset-item');
        const $itemsWithChildren = $menu.find('.menu-item-has-children');
        const $searchFlyout = $('#search-flyout');

        $trigger.on('click', (event) => {
            event.preventDefault();

            // if the search is open, close it
            if ($searchFlyout.hasClass('open')) {
                $searchFlyout.removeClass('open');
                $('#toggle-search-flyout').removeClass('active').find('.item-label').text('Search your area');
                $body.removeClass('disable-scrolling');
            }

            // open the menu
            $menu.addClass('visible');

            if ($(window).width() < 768) {
                $('body').addClass('navigation-open');
            }
        });

        $closeTrigger.on('click', (event) => {
            event.preventDefault();
            $menu.removeClass('visible');

            if ($(window).width() < 768) {
                $('body').removeClass('navigation-open');
            }
        });

        if ($itemsWithChildren.length) {
            $itemsWithChildren.children('a').on('click touchstart', function(event) {
                if ($(window).width() <= 600) {
                    event.preventDefault();
                    $(this).parent('li').toggleClass('open').children('.sub-menu').toggle();
                }
            });
        }
    }
});