$(function() {
/**
 * Bind individual agent events
 */
    $(document).on('click', '.toggle-agent-description', function() {
        let $trigger = $(this);
        let $descriptionEl = $trigger.parents('.agent-listing').find('.agent-description-wrapper');

        if ($descriptionEl.hasClass('open')) {
            $descriptionEl.removeClass('open').stop().slideUp();
            $trigger.text('About this agent');
        } else {
            $descriptionEl.addClass('open').stop().slideDown();
            $trigger.text('Hide');
        }
    });

    $(document).on('click', '.description-close', function() {
        let $parent = $(this).parents('.agent-listing');
        $parent.find('.agent-description-wrapper').removeClass('open').stop().slideUp();
        $parent.find('.toggle-agent-description').text('About this agent');
    });

/**
 * Load any necessary results in
 */
    const $outputTarget = $('.output-target[data-format="listing"]');

    if ($outputTarget.length) {
        let args = $outputTarget.data('ajax-args');
        let request;

        $outputTarget.addClass('loading');

        args.action = 'get_agent_results';
        request = $.ajax({
            url: window.site.ajax,
            type: 'GET',
            data: args
        });

        request.success((response) => {
            $outputTarget.html(response).removeClass('loading');
        });

        request.fail(() => alert('There was an error retrieving your search results. Please notify a site administrator.'));
    }
});