$(function() {
    const IMAGE_PATH = window.location.protocol + '//' + window.location.host + '/wp-content/themes/bestagentguide/assets/images/';
    const $tabs = $('#search-views');
    const $map = $('#search-results-map');

/**
 * Load any necessary results in
 */
    const $outputTarget = $('.output-target[data-format="map"]');

    if ($outputTarget.length) {
        let args = $outputTarget.data('ajax-args');
        let request;

        $outputTarget.addClass('loading');

        args.action = 'get_agent_results';
        request = $.ajax({
            url: window.site.ajax,
            type: 'GET',
            data: args
        });

        request.success((response) => {
            if (response) {
                $outputTarget.html(response).removeClass('loading');
            }

            loadMap($map);
        });

        request.fail(() => alert('There was an error retrieving your search results. Please notify a site administrator.'));
    }

    const loadMap = ($map) => {
        const $markers = $map.find('.marker');

        if ($map.length) {
            const $viewWrapper = $('#map-view');
            const $markerDetailsContainer = $('.marker-details-container');
            const icons = {
                default: {
                    url: IMAGE_PATH + 'icons/map/marker-default.png',
                    scaledSize: new google.maps.Size(24, 24),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(0, 0)
                },
                active: {
                    url: IMAGE_PATH + 'icons/map/marker-active.png',
                    scaledSize: new google.maps.Size(69, 69),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(22, 22)
                },
                premium: {
                    url: IMAGE_PATH + 'icons/map/marker-premium.png',
                    scaledSize: new google.maps.Size(43, 43),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(10, 10)
                },
                activePremium: {
                    url: IMAGE_PATH + 'icons/map/marker-premium-active.png',
                    scaledSize: new google.maps.Size(103, 102),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(39.5, 40)
                }
            };

            const MAP_DEFAULT_ZOOM = 12;

            let activeMarker = null;
            let mapCentralPoint = false;

            // close the marker details wrapper when the close button is clicked
            const DETAILS_WRAPPER_FADE_TIME = 300;

            $markerDetailsContainer.children('.details-close').on('click', () => {
                $markerDetailsContainer.removeClass('open');
                $viewWrapper.removeClass('detail-open');

                if ($(window).width() < 768) {
                    setTimeout(() => {
                        google.maps.event.trigger(map, 'resize');

                        if (typeof mapCentralPoint.extend === 'function') {
                            map.fitBounds(mapCentralPoint);
                        } else {
                            map.setCenter(mapCentralPoint);
                            map.setZoom(MAP_DEFAULT_ZOOM);
                        }
                        map[(typeof mapCentralPoint.extend === 'function') ? 'fitBounds' : 'setCenter'](mapCentralPoint);
                    }, 0);
                }

                setTimeout(() => $markerDetailsContainer.children('.agent-details').hide(), DETAILS_WRAPPER_FADE_TIME);

                if (activeMarker !== null) {
                    activeMarker.setIcon(activeMarker.premium ? icons.premium : icons.default);
                    activeMarker = null;
                }
            });

            // collect the marker positions and move the details to another container
            let markerData = [];
            let $agentDetails;

            if ($markers.length) {
                for (let i = 1, l = $markers.length; i <= l; i++) {
                    let $marker = $($markers[i - 1]);

                    markerData.push({
                        lat: parseFloat($marker.data('lat')),
                        lng: parseFloat($marker.data('lng')),
                        postID: $marker.data('post-id'),
                        premium: !!$marker.data('premium')
                    });

                    if ($marker.children('.agent-details').length) {
                        $marker.children('.agent-details').appendTo($markerDetailsContainer);
                    }
                }

                $agentDetails = $markerDetailsContainer.children('.agent-details');
            }

            // initialise the map
            let mapMarkerStore = [];
            let map = new google.maps.Map($map.get(0), {
                disableDefaultUI: false,
                mapTypeControl: false,
                gestureHandling: 'greedy',
                zoomControlOptions: {
                    position: google.maps.ControlPosition.LEFT_TOP
                },
                streetViewControlOptions: {
                    position: google.maps.ControlPosition.LEFT_TOP
                }
            });

            if ($markers.length && markerData.length > 1) { // if there are multiple markers, then use "fitBounds"
                let bounds = new google.maps.LatLngBounds();

                for (let i = 0, l = markerData.length; i < l; i++) {
                    let data = markerData[i];
                    let position = new google.maps.LatLng(data.lat, data.lng);
                    let marker = new google.maps.Marker({
                        map: map,
                        position: position,
                        icon: data.premium ? icons.premium : icons.default
                    });

                    marker.postID = data.postID;
                    marker.premium = data.premium;

                    mapMarkerStore.push(marker);
                    bounds.extend(position);
                }

                map.fitBounds(bounds);
                mapCentralPoint = bounds;
            } else if ($markers.length) { // if there's only one marker, then centralise the map to that
                let singleMarkerData = markerData[0];
                let position = new google.maps.LatLng(singleMarkerData.lat, singleMarkerData.lng);
                let marker = new google.maps.Marker({
                    map: map,
                    position: position,
                    icon: singleMarkerData.premium ? icons.premium : icons.default
                });

                marker.postID = singleMarkerData.postID;
                marker.premium = singleMarkerData.premium;

                mapMarkerStore.push(marker);
                map.setOptions({
                    center: position,
                    zoom: MAP_DEFAULT_ZOOM
                });

                mapCentralPoint = position;
            } else {
                mapCentralPoint = new google.maps.LatLng(52.559322, -4.174804);
                map.setCenter(mapCentralPoint);
                map.setZoom(6);
            }

            // add marker event(s) to change icon and open/close estate agent details
            if ($markers.length) {
                let working = false;

                for (let i = 0, l = mapMarkerStore.length; i < l; i++) {
                    let marker = mapMarkerStore[i];

                    marker.addListener('click', () => {
                        if (working) {
                            return;
                        }

                        // check if there's a marker already open
                        if (activeMarker !== null) {
                            let delay = 0;
                            let icon = activeMarker.premium ? icons.premium : icons.default;
                            let $activeDetail = $agentDetails.filter('#agent-map-detail-' + activeMarker.postID);

                            working = true;
                            activeMarker.setIcon(icon);

                            // if the open marker is the marker just clicked, close it
                            if (activeMarker === marker) {
                                $viewWrapper.removeClass('detail-open');
                                delay = DETAILS_WRAPPER_FADE_TIME;
                                $markerDetailsContainer.removeClass('open');
                                activeMarker = null;

                                if ($(window).width() < 768) {
                                    setTimeout(() => {
                                        google.maps.event.trigger(map, 'resize');
                                        map[(typeof mapCentralPoint.extend === 'function') ? 'fitBounds' : 'setCenter'](mapCentralPoint);
                                    }, 0);
                                }

                                working = false;

                                return;
                            }

                            // hide the active agent details depending on the delay
                            setTimeout(() => {
                                $activeDetail.hide();
                                working = false;
                            }, delay);
                        }

                        let $currentMarkerDetail = $agentDetails.filter('#agent-map-detail-' + marker.postID);

                        if ($currentMarkerDetail.length) {
                            $currentMarkerDetail.show().siblings().hide();
                        }

                        // center the map to the selected marker
                        if ($(window).width() < 768) {
                            setTimeout(() => google.maps.event.trigger(map, 'resize'), 0);
                        }

                        setTimeout(() => {
                            map.setCenter(marker.getPosition());

                            if ($(window).width() < 768) {
                                map.setZoom(15);
                            }
                        }, 50);

                        $markerDetailsContainer.addClass('open');
                        $viewWrapper.addClass('detail-open');
                        marker.setIcon(marker.premium ? icons.activePremium : icons.active);

                        activeMarker = marker;
                    });
                }
            }

            // re-paint map when the tab is activated
            let mapRepainted = false;

            $tabs.on('change.zf.tabs', (event, $el, $target) => {
                if (!mapRepainted && $target.attr('id') === 'map-view') {
                    google.maps.event.trigger(map, 'resize');

                    if (mapCentralPoint) {
                        map[(markerData.length > 1) ? 'fitBounds' : 'setCenter'](mapCentralPoint);
                    }

                    mapRepainted = true;
                }
            });

            // set map styles
            map.setOptions({
                'styles': [
                    {
                        "featureType": "water",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#e9e9e9"
                            },
                            {
                                "lightness": 17
                            }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#f5f5f5"
                            },
                            {
                                "lightness": 20
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            },
                            {
                                "lightness": 17
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            },
                            {
                                "lightness": 29
                            },
                            {
                                "weight": 0.2
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            },
                            {
                                "lightness": 18
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            },
                            {
                                "lightness": 16
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#f5f5f5"
                            },
                            {
                                "lightness": 21
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#dedede"
                            },
                            {
                                "lightness": 21
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "visibility": "on"
                            },
                            {
                                "color": "#ffffff"
                            },
                            {
                                "lightness": 16
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "saturation": 36
                            },
                            {
                                "color": "#333333"
                            },
                            {
                                "lightness": 40
                            }
                        ]
                    },
                    {
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#f2f2f2"
                            },
                            {
                                "lightness": 19
                            }
                        ]
                    },
                    {
                        "featureType": "administrative",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#fefefe"
                            },
                            {
                                "lightness": 20
                            }
                        ]
                    },
                    {
                        "featureType": "administrative",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "color": "#fefefe"
                            },
                            {
                                "lightness": 17
                            },
                            {
                                "weight": 1.2
                            }
                        ]
                    }
                ]
            });
        }
    }
});