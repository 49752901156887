/**
 * Flexible Content
 * 
 * @author FL1 Digital
 * @version 2.0.0
 */

// @codekit-prepend "inc/_fc-accordion.js";
// @codekit-prepend "inc/_fc-carousel.js";
// @codekit-prepend "inc/_fc-gallery.js";
// @codekit-prepend "inc/_fc-masonry.js";
// @codekit-prepend "inc/_fc-tabs.js";
// @codekit-prepend "inc/_fc-team.js";

(function ($, root, undefined) {

	// Accordion
	Accordion.init();

	// Carousels
    Carousels.fcLayout();
	Carousels.footerLogos();
	Carousels.gridBoxes();
	Carousels.images();
	Carousels.testimonials();

	// Gallery
    Gallery.init();

	// Tabs
    Tabs.init();

	// Team
    Team.init();

	// if we have scrolled past 300px show element .back-to-top
	$(window).scroll(function () {
		if ($(this).scrollTop() > 300) {
			$('.back-to-top').addClass('show');
		} else {
			$('.back-to-top').removeClass('show');
		}
	});

	$('.scroll').click(function (e) {
		e.preventDefault();
		var elementClicked = $(this).attr('href');
		var destination = $(elementClicked).offset().top;

		$('html:not(:animated),body:not(:animated)').animate(
			{
				scrollTop: destination - 170,
			},
			800
		);
	});
	
}(jQuery));