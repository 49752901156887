const $flyout = $('#search-flyout');

if ($flyout.length) {
    const $toggle = $('#toggle-search-flyout');
    const $body = $('body');
    const $offScreenMenu = $('#guide-menu-about');

    $toggle.on('click', () => {
        if ($flyout.hasClass('open')) {
            $flyout.removeClass('open');
            $body.removeClass('disable-scrolling');
            $toggle.removeClass('active').find('.item-label').text('Search your area');
        } else {
            // if the off-screen menu is open, close it
            if ($offScreenMenu.hasClass('visible')) {
                $offScreenMenu.removeClass('visible');
                $body.removeClass('navigation-open');
            }

            // open the menu
            $flyout.addClass('open');
            $toggle.addClass('active').find('.item-label').text('Close search');
            $body.addClass('disable-scrolling');
        }
    });

    // prevent the link from navigating the user if JavaScript is enabled
    $toggle.find('a').on('click', (event) => event.preventDefault());
}